<splus-forms id="LiquidacionPage" [submit]="submitted" [messageProcess]="messageProcess"
  [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">

  <form [formGroup]="FormRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
    [ngClass]="{'formGroup-read': processType == ProcessType.view}">

    <splus-forms-header [isLoading]="isLoading">

      <div class="flex direction-row items-center">
        <splus-forms-main-title [title]="title" [icon]="icon"> </splus-forms-main-title>


        @if(estado == 1){

        <div style="padding-top: 0.1em; padding-bottom: 0.1rem"
          class="text-sm px-3 bg-yellow-200 text-yellow-800 rounded-full">
          Borrador
        </div>

        }@else if(estado == 2){

        <div style="padding-top: 0.1em; padding-bottom: 0.1rem"
          class="text-sm px-3 bg-green-200 text-green-800 rounded-full">Liquidada</div>


        }@else if(estado == 3){

        <div style="padding-top: 0.1em; padding-bottom: 0.1rem"
          class="text-sm px-3 bg-teal-200 text-teal-800 rounded-full">
          Revisión
        </div>

        }@else if(estado == 4){
        <div style="padding-top: 0.1em; padding-bottom: 0.1rem"
          class="text-sm px-3 bg-blue-200 text-blue-800 rounded-full">
          <i class="fa-duotone fa-solid fa-lock text-orange-400"></i> Cerrada
        </div>
        }

        @if(estado == 5){
        <div style="padding-top: 0.1em; padding-bottom: 0.1rem"
          class="text-sm px-3 bg-red-200 text-red-800 rounded-full">
          Cancelada
        </div>
        }
      </div>

      <splus-forms-toolbar [options]="options">
        <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

          <button type="Submit" [disabled]="isLoading || !FormRecord.dirty" iconClass="fa-solid fa-floppy-disk"
            *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton rounded="small"
            themeColor="primary">
            Guardar
          </button>

          <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view"
            iconClass="fa-solid fa-pencil" kendoButton rounded="small" themeColor="primary" (click)="goEdit()">
            Editar
          </button>

          <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small" themeColor="base"
            (click)="cancelEdit()">
            Cancelar
          </button>

          <button *ngIf="processType == ProcessType.view" type="button" kendoButton rounded="small" themeColor="base"
            (click)="goback()">
            Volver
          </button>

          <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small" themeColor="base"
            (click)="clearForm()">
            Limpiar
          </button>

          @if (estado > 0 && estado < 4) { <span class="option-separator pr-5 text-gray-300">|</span>
            }

            @if (estado == 1) {

            <button type="button" kendoButton rounded="small" class="" themeColor="base"
              (click)="MarcarComoLiquidada()">
              Marcar como liquidada
            </button>
            }@else if (estado == 2) {
            <button type="button" kendoButton rounded="small" class="" themeColor="base" (click)="MarcarComoRevision()">
              Marcar como revisión
            </button>
            }@else if (estado == 3) {
            <button type="button" kendoButton rounded="small" class="" themeColor="base" (click)="MarcarComoCerrada()">
              Marcar como cerrada
            </button>
            }

        </splus-forms-toolbar-buttons>


      </splus-forms-toolbar>



    </splus-forms-header>

    <splus-forms-fields [processType]="processType">

      <splus-forms-group title="Información Básica" #separadorInformacionBasica>


        <splus-field *ngVar="f.nombre as ctrl" [splusFormControl]="ctrl">
          <kendo-textbox splusInput [splusGroup]="separadorInformacionBasica" type="text" [id]="ctrl.nameControl"
            [formControl]="ctrl">

            <ng-template kendoTextBoxPrefixTemplate>

              @if(estado == 4 || estado == 5){
              <div class="m-1">
                <i class="fa-duotone fa-solid fa-lock text-primary dark:text-blue-500"></i>
              </div>
              <kendo-textbox-separator></kendo-textbox-separator>
              }
            </ng-template>

          </kendo-textbox>
        </splus-field>

        <splus-field *ngVar="f.fechaInicio as ctrl" [splusFormControl]="ctrl" #fecha>

          <kendo-datepicker #calendar splusInput [navigation]="true" format="dd/MM/yyyy" [formControl]="ctrl"
            [splusGroup]="separadorInformacionBasica" [splusField]="fecha" [value]="ctrl.value">

            <ng-template kendoCalendarNavigationItemTemplate let-title>
              {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
            </ng-template>

          </kendo-datepicker>

          <ng-template splusFieldViewModeTemplate>
            {{ calendar.value | date:'dd/MM/yyyy' }}
          </ng-template>


        </splus-field>

        <splus-field *ngVar="f.fechaFin as ctrl" [splusFormControl]="ctrl" #fecha>

          <kendo-datepicker #calendar splusInput [navigation]="true" format="dd/MM/yyyy" [formControl]="ctrl"
            [splusGroup]="separadorInformacionBasica" [splusField]="fecha" [value]="ctrl.value">

            <ng-template kendoCalendarNavigationItemTemplate let-title>
              {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
            </ng-template>

          </kendo-datepicker>

          <ng-template splusFieldViewModeTemplate>
            {{ calendar.value | date:'dd/MM/yyyy' }}
          </ng-template>

        </splus-field>

        <splus-field *ngVar="f.descripcion as ctrl" [splusFormControl]="ctrl">
          <kendo-textarea splusInput [splusGroup]="separadorInformacionBasica" type="text" [id]="ctrl.nameControl"
            [formControl]="ctrl">

          </kendo-textarea>
        </splus-field>




        @if(id > 0)
        {
        <splus-field-data-info class="sticky top-4 h-fit bg-blue-50 rounded mt-2 ">
          <div>
            Total Devengados: <span class="font-medium"> {{calcularTotales()?.totalDevengados | currency:'COP':'symbol':'1.0-0'}}</span>
          </div>

          <div>
            Total Deducciones: <span class="font-medium">{{calcularTotales()?.totalDeducciones | currency:'COP':'symbol':'1.0-0'}}</span>
          </div>
        </splus-field-data-info>
        }


      </splus-forms-group>



      <splus-tabs [keepTabContent]="true">
        <splus-tab [selected]="true" *ngIf="processType != ProcessType.create && id">

          <ng-template splusTabTitle>
            <span class="select-none"> <i class=" text-nomina fa-duotone fa-solid fa-memo-pad mr-1"></i>
              Empleados</span>
          </ng-template>

          <ng-template splusTabContent>

            <div class="mb-1 mt-2">

              @if(this.estado != 4 && this.estado != 5){



              <div class="flex flex-row justify-between items-center w-full">

                <div>
                  <button type="button" themeColor="base" class="p-0 m-0" kendoButton
                    [disabled]="!(processType == ProcessType.edit)" (click)="openedAgregarEmpleado()">

                    <i class="fa-solid fa-plus  text-primary dark:text-blue-500 mr-1"></i>
                    <span class="text-normal dark:text-white">
                      Agregar Empleado
                    </span>
                  </button>

                  <button type="button" [disabled]="!(processType == ProcessType.edit) || !showDeleteButton"
                    themeColor="base" class="p-0 m-0 ml-2" kendoButton (click)="dialogEliminarEmpleadosSelecionados()">
                    <i class="fa-solid fa-trash-can  text-primary dark:text-blue-500 mr-1"></i>
                    <span class="text-normal dark:text-white">
                      Remover Empleados
                    </span>
                  </button>
                </div>
                <div>

                  <!--

                  <button type="button" [disabled]="!(processType == ProcessType.edit) || !showLiquidacionMasivaButton"
                    themeColor="base" class="p-0 m-0 ml-2" kendoButton (click)="openedLiquidarMasivoEmpleado()">
                    <i class="fa-duotone fa-solid fa-pen-to-square text-primary dark:text-blue-500 mr-1"></i>
                    <span class="text-normal dark:text-white">
                      Liquidación Masiva
                    </span>
                  </button>-->


                </div>
              </div>

              }


            </div>
            <div
              class="w-full mt-2 mb-2 barraV3 leading-6 ring-1 ring-slate-900/10 shadow-sm py-1.5 pl-2 pr-3 hover:ring-slate-300 dark:bg-slate-800 dark:highlight-white/5 dark:hover:bg-slate-700  overflow-hidden rounded flex">
              <div class="flex flex-row justify-between items-center  w-full m">
                <i class="fa-solid fa-magnifying-glass p-1 opacity-50 text-normal dark:text-white"></i>

                <div class="flex-grow mr-8 min-w-220 flex ">
                  <input placeholder="Buscar" (keyup.enter)="searchEmpleadosMainControl()" tegettInput id="search-input"
                    type="text" autocomplete="off" [formControl]="searchEmpleadosMainControl"
                    class="text-normal dark:text-white w-full border-none bg-inherit outline-none p-1 px-11 pl-2 resize-none">
                </div>


              </div>
            </div>


            <div>
              <kendo-grid #gridEmpleados [data]="dataGridEmpleados" [resizable]="true" [autoSize]="true" class=""
                [selectable]="true" [selectedKeys]="indexSelectionRow" [selectable]="{checkboxOnly: true}"
                [kendoGridSelectBy]="'id'" (selectedKeysChange)="selecctionChage($event)">


                <kendo-grid-checkbox-column [showSelectAll]="true"
                  [hidden]="!(processType == ProcessType.edit) || this.estado == 4 || this.estado == 5"></kendo-grid-checkbox-column>

                <kendo-grid-column title="Liquidación" width="40" [minResizableWidth]="150">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="text-center">
                      @if(dataItem.liquidado == true){
                      <i class="  fa-solid  fa-circle-check  text-green-600"></i>
                      Completo
                      }@else{
                      Pendiente
                      }
                    </div>
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Empleado">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                    <a href="/nomina/empleados/{{dataItem?.id}}" target="_blank" class="info ng-star-inserted">
                      <i class="fa-duotone fa-solid fa-user-tie-hair dark:text-yellow-500 text-orange-400"></i>
                      <span class="text-link hover:underline pointer dark:text-link-dark-blue">
                        {{dataItem?.nombre}}
                      </span>

                    </a>

                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="cargo" title="Cargo">
                </kendo-grid-column>

                <kendo-grid-column field="tipoDocumento" title="Tipo de Documento">
                </kendo-grid-column>

                <kendo-grid-column field="numeroDocumento" title="Número Indentificación">
                </kendo-grid-column>

                <kendo-grid-column title="Contrato">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <a href="/nomina/contratos/{{dataItem?.idContrato}}" target="_blank" class="info ng-star-inserted">
                      <i class="fa-duotone fa-solid fa-memo-pad dark:text-yellow-500 text-blue-500"></i>
                      <span class="text-link hover:underline pointer dark:text-link-dark-blue">
                        {{dataItem?.idContrato}}
                      </span>

                    </a>

                  </ng-template>
                </kendo-grid-column>


                <kendo-grid-column title="Devengado">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem?.totalDevengado | currency:'COP':'symbol':'1.0-0' }}
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Deducción">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem?.totalDeduccion | currency:'COP':'symbol':'1.0-0' }}
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="Opciones" width="100%">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                    <div class="flex flex-row justify-between items-center w-full">


                      @if(dataItem?.idLiquidacion > 0 && processType != ProcessType.view){

                      <button kendoButton type="button" (click)="PopupLiquidarEmpleado(dataItem)" size="small">
                        @if(this.estado == 4){

                        Ver Liquidación
                        }@else {
                        <i class="fa-solid fa-pen-to-square text-primary"></i> Liquidar
                        }
                      </button>
                      }


                    </div>


                  </ng-template>
                </kendo-grid-column>

              </kendo-grid>

            </div>
          </ng-template>
        </splus-tab>

      </splus-tabs>

    </splus-forms-fields>

  </form>
</splus-forms>

@if(openedDiagAgregarEmpleado)
{
<kendo-dialog [themeColor]="'primary'" title="Agregar Empleados" (close)="closeEmpleado('cancel')" [minWidth]="450"
  width="90%">
  <div>
    <app-search-in-list [selectAllFlag]="selectAllFlag" #searchListEmployees [inPopup]="true"
      (SendDataEmitter)="GetSelectionEmpleado($event)" [selectedItems]="selectedItems"
      (LoadSelectData)="GetSelectionEmpleado($event)" [setting]="ListadoEmpleado" [filtersData]="filtersEmpleado">
    </app-search-in-list>
  </div>

  <kendo-dialog-actions>
    <div class="flex flex-row justify-between items-center">
      <div>
        <button kendoButton iconClass="fa-sharp fa-regular fa-check-to-slot" (click)="toggleSelectAll()">Seleccionar
          Todos</button>
      </div>
      <div>
        <button kendoButton (click)="closeEmpleado('cancel') " class="mr-2">Cancelar</button>
        <button kendoButton (click)="callLoadSelect()" themeColor="primary" iconClass="fa-solid fa-check">
          Agregar Seleccionados
        </button>
      </div>
    </div>
  </kendo-dialog-actions>

</kendo-dialog>
}

@if(openedDiagLiquidarEmpleado){
<kendo-dialog [themeColor]="'primary'" title="Liquidar Empleado" (close)="closeLiquidarEmpleado('cancel')"
  [minWidth]="450" width="90%" height="90%">

  <div class="shadow-md rounde px-2 py-2 justify-between bg-white dark:bg-gray-700 rounded">

    <div class="mb-2">
      <i class="dark:text-yellow-500 text-orange-400 fa-duotone fa-solid fa-user-tie-hair"></i>

      <span class=" text-base font-semibold"> {{dataEmpleadoALiquidar?.nombre}}</span>
    </div>
    <div>
      <i class="dark:text-yellow-500 text-orange-400 fa-duotone fa-solid fa-briefcase "></i>
      <span class=" text-base font-semibold"> {{dataEmpleadoALiquidar?.cargo}}</span>
    </div>
  </div>
  <div>

    <splus-tabs [keepTabContent]="true" (tabSelect)="onTabChange($event)" #tabsComponent>
      <splus-tab [selected]="true">

        <ng-template splusTabTitle>
          <span class="select-none"> <i class=" text-nomina   fa-regular fa-book-sparkles mr-1"></i>
            Conceptos disponibles</span>
        </ng-template>

        <ng-template splusTabContent>


          <div class="mb-1 mt-2">
            @if(this.estado != 4 && this.estado != 5){
            <button type="button" themeColor="base" class="p-0 m-0" kendoButton
              [disabled]="dataSelectedDisponibles.length == 0" (click)="openedAgregarConseptos()">

              <i class="fa-solid fa-plus  text-primary dark:text-blue-500 mr-1"></i>
              <span class="text-normal dark:text-white">
                Aplicar Concepto
              </span>
            </button>
            }

          </div>
          <div
            class="w-full mt-2 barraV3 leading-6 ring-1 ring-slate-900/10 shadow-sm py-1.5 pl-2 pr-3 hover:ring-slate-300 dark:bg-slate-800 dark:highlight-white/5 dark:hover:bg-slate-700  overflow-hidden rounded flex">
            <div class="flex flex-row justify-between items-center  w-full m">
              <i class="fa-solid fa-magnifying-glass p-1 opacity-50 text-normal dark:text-white"></i>

              <div class="flex-grow mr-8 min-w-220 flex ">
                <input placeholder="Buscar" (keyup.enter)="SearchDisponibles()" tegettInput id="search-input"
                  type="text" autocomplete="off" [formControl]="searchMainControl"
                  class="text-normal dark:text-white w-full border-none bg-inherit outline-none p-1 px-11 pl-2 resize-none">
              </div>


            </div>
          </div>

          <div>

            <kendo-grid #gridLiquidarEmpleados [data]="dataConceptosDisponibles" class="mt-2" [selectable]="true"
              resizable="true" [kendoGridSelectBy]="'idConcepto'" [selectedKeys]="indexSelectionRowAplicados"
              [loading]="loadingLiquidar" (selectedKeysChange)="selecctionChageConceptosDisponibles($event)"
              [selectable]="{checkboxOnly: true}">


              <kendo-grid-checkbox-column [hidden]="this.estado == 4 || this.estado == 5" [showSelectAll]="true"
                width="40"></kendo-grid-checkbox-column>


              <kendo-grid-column title="Concepto">
                <ng-template kendoGridCellTemplate let-dataItem>

                  <div class="font-semibold"> {{dataItem?.llaveConceptoAplicada}}</div>
                  <div class="text-sm">{{dataItem?.tercero}}</div>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="tipoConcepto" title="Tipo Concepto">

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                  <div>{{dataItem?.tipoConcepto}}</div>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="tipoNovedad" title="Grupo Concepto">
              </kendo-grid-column>

              <kendo-grid-column field="formulaAplicada" title="Fórmula">
              </kendo-grid-column>


              <kendo-grid-column field="valorCalculado" title="Valor Calculado">
                <ng-template kendoGridCellTemplate let-dataItem>

                  @if(dataItem?.tipoConcepto == 'Variable' && dataItem?.tipoNovedad == 'Cantidad')
                  {
                    {{dataItem?.valorCalculado}}
                  }@else{
                  <div>

                    {{ dataItem?.valorCalculado | currency:'COP':'symbol':'1.0-0' }}
                  </div>
                  }
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="nombreTercero" title="Tercero">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                  <div>{{dataItem?.nombreTercero}}</div>

                </ng-template>
              </kendo-grid-column>



            </kendo-grid>

          </div>
        </ng-template>
      </splus-tab>

      <splus-tab>

        <ng-template splusTabTitle>
          <span class="select-none"> <i class=" text-orange-600  fa-regular fa-book-sparkles mr-1"></i>
            Conceptos Aplicados</span>
        </ng-template>

        <ng-template splusTabContent>

          <div class="mb-1 mt-2">

            @if(this.estado != 4 && this.estado != 5){
            <button type="button" [disabled]="dataSelectedAplicados.length == 0" themeColor="base" class="p-0 m-0 ml-2"
              kendoButton (click)="dialogEliminarConceptosAplicadosSelecionados()">
              <i class="fa-solid fa-trash-can  text-primary dark:text-blue-500 mr-1"></i>
              <span class="text-normal dark:text-white">
                Remover Conceptos Aplicados
              </span>
            </button>
            }

          </div>
          <div>
            <kendo-grid #gridLiquidarEmpleados [data]="dataConceptosAplicados" class="mt-2" [selectable]="true"
              resizable="true" [kendoGridSelectBy]="'idConcepto'" [selectedKeys]="indexSelectionRowAplicados"
              [loading]="loadingLiquidar" (selectedKeysChange)="selecctionChageConceptosAplicados($event)"
              [selectable]="{checkboxOnly: true}">


              <kendo-grid-checkbox-column [hidden]="this.estado == 4 || this.estado == 5" [showSelectAll]="true"
                width="40"></kendo-grid-checkbox-column>


              <kendo-grid-column title="Concepto">
                <ng-template kendoGridCellTemplate let-dataItem>

                  <div class="font-semibold"> {{dataItem?.llaveConceptoAplicada}}</div>
                  <div class="text-sm">{{dataItem?.tercero}}</div>

                </ng-template>
              </kendo-grid-column>



              <kendo-grid-column field="tipoConcepto" title="Tipo Concepto">

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="t-row">
                    @if(dataItem?.tipoConcepto == 'Devengados'){
                    <i class="text-sm fa-solid text-green-600 fa-plus"></i>
                    }
                    @if(dataItem?.tipoConcepto == 'Deducciones'){
                    <i class="text-sm fa-solid text-red-600 fa-minus"></i>
                    }
                    @if(dataItem?.tipoConcepto == 'Variable'){
                    <i class="text-sm fa-solid text-blue-400 fa-diamond"></i>
                    }
                    <div>{{dataItem?.tipoConcepto}}</div>
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="tipoNovedad" title="Grupo Concepto">
              </kendo-grid-column>

              <kendo-grid-column field="formulaAplicada" title="Fórmula">
              </kendo-grid-column>
              <kendo-grid-column field="cantidad" title="Cantidad">
                <ng-template kendoGridCellTemplate let-dataItem>
                  @if(dataItem?.aplicaCantidad)
                  {

                  <kendo-numerictextbox format="n0" [spinners]="false" (valueChange)="onValueChange($event, dataItem)"
                    [(ngModel)]="dataItem.cantidad">
                  </kendo-numerictextbox>


                  }
                  @else {
                  {{dataItem.cantidad}}
                  }


                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="valorCalculado" title="Valor Calculado">
                <ng-template kendoGridCellTemplate let-dataItem>

                  @if(dataItem?.tipoConcepto == 'Variable' && dataItem?.tipoNovedad == 'Cantidad')
                  {
                  <div class="bold">
                    {{dataItem?.valorCalculado}}
                  </div>
                  }@else{
                  <div class="bold"
                    [ngClass]="{'text-blue-600 dark:text-blue-400': dataItem?.tipoConcepto == 'Devengados', 'text-red-600 dark:text-red-400': dataItem?.tipoConcepto == 'Deducciones'}">

                    {{ dataItem?.valorCalculado | currency:'COP':'symbol':'1.0-0' }}
                  </div>
                  }
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="valorPersonalizado" title="Valor Personalizado" [width]="250">
                <ng-template kendoGridCellTemplate let-dataItem>
                  @if(dataItem?.editable && this.estado != 4 && this.estado != 5)
                  {

                  <kendo-numerictextbox format="n0" [spinners]="false" (valueChange)="onValueChange($event, dataItem)"
                    [(ngModel)]="dataItem.valorPersonalizado">
                  </kendo-numerictextbox>


                  }@else{
                  @if((this.estado == 4 || this.estado == 5) && dataItem?.tipoNovedad == 'Cantidad'){
                  {{dataItem?.valorPersonalizado}}

                  }@else {
                  {{dataItem?.valorPersonalizado | currency:'COP':'symbol':'1.0-0'}}
                  }
                  }

                </ng-template>
              </kendo-grid-column>

              <ng-template kendoGridStatusBarTemplate let-dataItem>

                <div
                  class="bg-white dark:bg-slate-800 shadow-lg rounded-lg p-2 min-w-[300px] space-y-2 border border-gray-200 dark:border-gray-700">
                  <div
                    class="flex items-center justify-between group hover:bg-gray-50 dark:hover:bg-slate-700   rounded-md transition-all">
                    <span class="text-gray-700 dark:text-gray-200 font-medium">
                      Total Devengado
                    </span>
                    <span class="text-blue-600 dark:text-blue-400  text-lg font-semibold">
                      {{totalDevengado | currency:'COP':'symbol':'1.0-0'}}
                    </span>
                  </div>

                  <div
                    class="flex items-center justify-between group hover:bg-gray-50 dark:hover:bg-slate-700 rounded-md transition-all">
                    <span class="text-gray-700 dark:text-gray-200 font-medium">
                      Total Deducción
                    </span>
                    <span class="text-red-600 dark:text-red-400 text-lg font-semibold">
                      {{totalDeducciones | currency:'COP':'symbol':'1.0-0'}}
                    </span>
                  </div>

                  <div class="border-t border-gray-200 dark:border-gray-700 pt-2">
                    <div class="flex items-center justify-between bg-gray-50 dark:bg-slate-700 p-1 rounded-md">
                      <span class="text-gray-800 dark:text-white font-bold text-lg">
                        Total
                      </span>
                      <span class="text-green-600 dark:text-green-400 font-bold text-lg">
                        {{total | currency:'COP':'symbol':'1.0-0'}}
                      </span>
                    </div>
                  </div>
                </div>

              </ng-template>

            </kendo-grid>
          </div>
        </ng-template>
      </splus-tab>

    </splus-tabs>


  </div>

  <kendo-dialog-actions layout="end">

    @if(this.estado == 4 || this.estado == 5){
    <button kendoButton type="button" (click)="closeLiquidarEmpleado('cancel')">Cerrar</button>
    }@else{
    <button kendoButton type="button" (click)="closeLiquidarEmpleado('cancel')">Cancelar</button>

    <button kendoButton [hidden]="!showbtnActions" type="button" [disabled]="dataConceptosAplicados.length == 0"
      (click)="onRecalcularData()" themeColor="warning" iconClass="fa-light fa-money-check-dollar-pen">
      Validar Conceptos
    </button>

    <button kendoButton [hidden]="!showbtnActions" type="button"
      [disabled]="dataConceptosAplicados.length == 0 || !datosValidos" (click)="onGuardarData()" themeColor="primary"
      iconClass="fa-solid fa-save">
      Guardar
    </button>
    }


  </kendo-dialog-actions>
</kendo-dialog>
}


<!-- Masivo Presonalizado -->
@if(openedDiagLiquidarMasivoEmpleado){
<kendo-dialog [themeColor]="'primary'" title="Liquidar Masivo Empleado" (close)="closeLiquidarMasivoEmpleado('cancel')"
  [minWidth]="450" width="90%" height="90%">

  <div class="shadow-md rounde px-2 py-2 justify-between bg-white dark:bg-gray-700 rounded">

    <div class="mb-2">
      <i class="dark:text-yellow-500 text-orange-400 fa-duotone fa-solid fa-user-tie-hair"></i>
      Empleados

      <span class="">
        <kendo-multiselecttree kendoMultiSelectTreeExpandable [kendoMultiSelectTreeHierarchyBinding]="data"
          childrenField="employees" textField="text" valueField="id" [filterable]="true" [tagMapper]="tagMapper"
          [expandedKeys]="['0']">
          <ng-template kendoMultiSelectTreeTagTemplate let-dataItem>
            {{ dataItem.text }}
          </ng-template>
        </kendo-multiselecttree>
      </span>
    </div>
    <div>
      <i class="dark:text-yellow-500 text-orange-400 fa-duotone fa-solid fa-briefcase "></i>
      <span class=" text-base font-semibold"> {{dataEmpleadoALiquidar?.cargo}}</span>
    </div>
  </div>
  <div>

    <splus-tabs [keepTabContent]="true" (tabSelect)="onTabChange($event)" #tabsComponent>
      <splus-tab [selected]="true">

        <ng-template splusTabTitle>
          <span class="select-none"> <i class=" text-nomina   fa-regular fa-book-sparkles mr-1"></i>
            Conceptos disponibles</span>
        </ng-template>

        <ng-template splusTabContent>


          <div class="mb-1 mt-2">
            @if(this.estado != 4 && this.estado != 5){
            <button type="button" themeColor="base" class="p-0 m-0" kendoButton
              [disabled]="dataSelectedDisponibles.length == 0" (click)="openedAgregarConseptos()">

              <i class="fa-solid fa-plus  text-primary dark:text-blue-500 mr-1"></i>
              <span class="text-normal dark:text-white">
                Aplicar Concepto
              </span>
            </button>
            }

          </div>

          <div>

            <kendo-grid #gridLiquidarEmpleados [data]="dataConceptosDisponibles" class="mt-2" [selectable]="true"
              resizable="true" [kendoGridSelectBy]="'idConcepto'" [selectedKeys]="indexSelectionRowAplicados"
              [loading]="loadingLiquidar" (selectedKeysChange)="selecctionChageConceptosDisponibles($event)"
              [selectable]="{checkboxOnly: true}" [filterable]="true">


              <kendo-grid-checkbox-column [hidden]="this.estado == 4 || this.estado == 5" [showSelectAll]="true"
                width="40"></kendo-grid-checkbox-column>


              <kendo-grid-column title="Concepto">
                <ng-template kendoGridCellTemplate let-dataItem>

                  <div class="font-semibold"> {{dataItem?.llaveConceptoAplicada}}</div>
                  <div class="text-sm">{{dataItem?.tercero}}</div>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="tipoConcepto" title="Tipo Concepto">

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                  <div>{{dataItem?.tipoConcepto}}</div>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="tipoNovedad" title="Grupo Concepto">
              </kendo-grid-column>

              <kendo-grid-column field="formulaAplicada" title="Fórmula">
              </kendo-grid-column>

              <kendo-grid-column field="valorCalculado" title="Valor Calculado">
                <ng-template kendoGridCellTemplate let-dataItem>

                  @if(dataItem?.tipoConcepto == 'Variable' && dataItem?.tipoNovedad == 'Cantidad')
                  {
                  {{dataItem?.valorCalculado}}
                  }@else{
                  <div>

                    {{ dataItem?.valorCalculado | currency:'COP':'symbol':'1.0-0' }}
                  </div>
                  }
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="nombreTercero" title="Tercero">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                  <div>{{dataItem?.nombreTercero}}</div>

                </ng-template>
              </kendo-grid-column>



            </kendo-grid>

          </div>
        </ng-template>
      </splus-tab>

      <splus-tab>

        <ng-template splusTabTitle>
          <span class="select-none"> <i class=" text-orange-600  fa-regular fa-book-sparkles mr-1"></i>
            Conceptos Aplicados</span>
        </ng-template>

        <ng-template splusTabContent>

          <div class="mb-1 mt-2">

            @if(this.estado != 4 && this.estado != 5){
            <button type="button" [disabled]="dataSelectedAplicados.length == 0" themeColor="base" class="p-0 m-0 ml-2"
              kendoButton (click)="dialogEliminarConceptosAplicadosSelecionados()">
              <i class="fa-solid fa-trash-can  text-primary dark:text-blue-500 mr-1"></i>
              <span class="text-normal dark:text-white">
                Remover Conceptos Aplicados
              </span>
            </button>
            }

          </div>
          <div>
            <kendo-grid #gridLiquidarEmpleados [data]="dataConceptosAplicados" class="mt-2" [selectable]="true"
              resizable="true" [kendoGridSelectBy]="'idConcepto'" [selectedKeys]="indexSelectionRowAplicados"
              [loading]="loadingLiquidar" (selectedKeysChange)="selecctionChageConceptosAplicados($event)"
              [selectable]="{checkboxOnly: true}">


              <kendo-grid-checkbox-column [hidden]="this.estado == 4 || this.estado == 5" [showSelectAll]="true"
                width="40"></kendo-grid-checkbox-column>


              <kendo-grid-column title="Concepto">
                <ng-template kendoGridCellTemplate let-dataItem>

                  <div class="font-semibold"> {{dataItem?.llaveConceptoAplicada}}</div>
                  <div class="text-sm">{{dataItem?.tercero}}</div>

                </ng-template>
              </kendo-grid-column>



              <kendo-grid-column field="tipoConcepto" title="Tipo Concepto">

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div class="t-row">
                    @if(dataItem?.tipoConcepto == 'Devengados'){
                    <i class="text-sm fa-solid text-green-600 fa-plus"></i>
                    }
                    @if(dataItem?.tipoConcepto == 'Deducciones'){
                    <i class="text-sm fa-solid text-red-600 fa-minus"></i>
                    }
                    @if(dataItem?.tipoConcepto == 'Variable'){
                    <i class="text-sm fa-solid text-blue-400 fa-diamond"></i>
                    }
                    <div>{{dataItem?.tipoConcepto}}</div>
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="tipoNovedad" title="Grupo Concepto">
              </kendo-grid-column>

              <kendo-grid-column field="formulaAplicada" title="Fórmula">
              </kendo-grid-column>

              <kendo-grid-column field="valorCalculado" title="Valor Calculado">
                <ng-template kendoGridCellTemplate let-dataItem>

                  @if(dataItem?.tipoConcepto == 'Variable' && dataItem?.tipoNovedad == 'Cantidad')
                  {
                  <div class="bold">
                    {{dataItem?.valorCalculado}}
                  </div>
                  }@else{
                  <div class="bold"
                    [ngClass]="{'text-blue-600 dark:text-blue-400': dataItem?.tipoConcepto == 'Devengados', 'text-red-600 dark:text-red-400': dataItem?.tipoConcepto == 'Deducciones'}">

                    {{ dataItem?.valorCalculado | currency:'COP':'symbol':'1.0-0' }}
                  </div>
                  }
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="valorPersonalizado" title="Valor Personalizado" [width]="250">
                <ng-template kendoGridCellTemplate let-dataItem>
                  @if(dataItem?.tipoConcepto == 'Variable' && this.estado != 4 && this.estado != 5)
                  {

                  <kendo-numerictextbox format="n0" [spinners]="false" (valueChange)="onValueChange($event, dataItem)"
                    [(ngModel)]="dataItem.valorPersonalizado">
                  </kendo-numerictextbox>


                  }@else{
                  @if((this.estado == 4 || this.estado == 5) && dataItem?.tipoNovedad == 'Cantidad'){
                  {{dataItem?.valorPersonalizado}}

                  }@else {
                  {{dataItem?.valorPersonalizado | currency:'COP':'symbol':'1.0-0'}}
                  }
                  }

                </ng-template>
              </kendo-grid-column>

              <ng-template kendoGridStatusBarTemplate let-dataItem>

                <div
                  class="bg-white dark:bg-slate-800 shadow-lg rounded-lg p-2 min-w-[300px] space-y-2 border border-gray-200 dark:border-gray-700">
                  <div
                    class="flex items-center justify-between group hover:bg-gray-50 dark:hover:bg-slate-700   rounded-md transition-all">
                    <span class="text-gray-700 dark:text-gray-200 font-medium">
                      Total Devengado
                    </span>
                    <span class="text-blue-600 dark:text-blue-400  text-lg font-semibold">
                      {{totalDevengado | currency:'COP':'symbol':'1.0-0'}}
                    </span>
                  </div>

                  <div
                    class="flex items-center justify-between group hover:bg-gray-50 dark:hover:bg-slate-700 rounded-md transition-all">
                    <span class="text-gray-700 dark:text-gray-200 font-medium">
                      Total Deducción
                    </span>
                    <span class="text-red-600 dark:text-red-400 text-lg font-semibold">
                      {{totalDeducciones | currency:'COP':'symbol':'1.0-0'}}
                    </span>
                  </div>

                  <div class="border-t border-gray-200 dark:border-gray-700 pt-2">
                    <div class="flex items-center justify-between bg-gray-50 dark:bg-slate-700 p-1 rounded-md">
                      <span class="text-gray-800 dark:text-white font-bold text-lg">
                        Total
                      </span>
                      <span class="text-green-600 dark:text-green-400 font-bold text-lg">
                        {{total | currency:'COP':'symbol':'1.0-0'}}
                      </span>
                    </div>
                  </div>
                </div>

              </ng-template>

            </kendo-grid>
          </div>
        </ng-template>
      </splus-tab>

    </splus-tabs>


  </div>



  <kendo-dialog-actions layout="end">

    @if(this.estado == 4 || this.estado == 5){
    <button kendoButton type="button" (click)="closeLiquidarMasivoEmpleado('cancel')">Cerrar</button>
    }@else{
    <button kendoButton type="button" (click)="closeLiquidarMasivoEmpleado('cancel')">Cancelar</button>

    <button kendoButton [hidden]="!showbtnActions" type="button" [disabled]="dataConceptosAplicados.length == 0"
      (click)="onRecalcularData()" themeColor="warning" iconClass="fa-light fa-money-check-dollar-pen">
      Validar Conceptos
    </button>

    <button kendoButton [hidden]="!showbtnActions" type="button"
      [disabled]="dataConceptosAplicados.length == 0 || !datosValidos" (click)="onGuardarData()" themeColor="primary"
      iconClass="fa-solid fa-save">
      Guardar
    </button>
    }


  </kendo-dialog-actions>
</kendo-dialog>
}





<ng-container #container *ngIf="!closePop"></ng-container>
