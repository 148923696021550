import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { ControllerApiList } from "@app/models/SaludPlus/Controller/ControllerList";
import { SaludPlusApiResponse } from "@src/app/models/base/SaludplusApiResponse";
import { map, Observable } from "rxjs";
import { SplusBaseService } from "@src/app/services/Generals/splusBase.service";
import { ILiquidacionNominaContratoEmpleadoDTO, ILiquidacionNominaEmpleadoConceptoDTO } from "@src/app/models/Nomina/Liquidacion.model";


@Injectable({
  providedIn: "root",
})
export class LiquidacionService extends SplusBaseService {
  static instance: LiquidacionService;
  error: HttpErrorResponse;

  constructor() {
    super();
    LiquidacionService.instance = this;
    this.nameEndPoint = `${ControllerApiList.Nomina.Liquidacion}`
  }

 

  ConceptosConfigurados<T>(params: { [param: string]: string }): Observable<T> {
    const record = this.http.post<SaludPlusApiResponse<T>>(
      `${this.nameEndPoint}/ConceptosConfigurados`,params
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    return record;

  }
  ReCalcularConceptos<T>(params: ILiquidacionNominaEmpleadoConceptoDTO[]): Observable<T> {
    const record = this.http.post<SaludPlusApiResponse<T>>(
      `${this.nameEndPoint}/ReCalcularConceptos`,params
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    return record;

  }

  ActualizarConceptos<T>(data: ILiquidacionNominaContratoEmpleadoDTO): Observable<T> {
    const record = this.http.put<SaludPlusApiResponse<T>>(
      `${this.nameEndPoint}/ActualizarConceptosEmpleados`,data
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    return record;

  }


  CambiarEstado<T>(data: { idLiquidacion: number, estado: number }): Observable<T> {

    const params = new HttpParams()
    .set('idLiquidacion', data.idLiquidacion.toString())
    .set('estado', data.estado.toString());


    const record = this.http.put<SaludPlusApiResponse<T>>(
      `${this.nameEndPoint}/ActualizarEstado`,{} , {params}
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    return record;

  }

  

}
