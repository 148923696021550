import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { AppSettings } from "@app/helpers/AppSettings";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component"; 
import { LiquidacionSettings } from "./config/liquidacion.settings";
import { LiquidacionComponent } from "./components/liquidacion.component";
import { LiquidacionSearch } from "./config/liquidacion.search";

const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Liquidacion Listado" },
  },
  { path: "", component: LiquidacionComponent },
  { path: ":id", component: LiquidacionComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LiquidacionRoutingModule {
 
  //Routing principal
  public static RoutingLiquidacion = {
    path: "nomina/liquidacion",
    loadChildren: () =>
      import("./liquidacion.module").then(
        (m) => m.LiquidacionModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Liquidación Nómina`,
      icon: "fa-duotone fa-solid fa-books",
      setting: LiquidacionSettings,
      search : LiquidacionSearch
    },
  };
}
