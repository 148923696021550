import { UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, inject } from "@angular/core";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import Validation, { rangoFechasValidator } from "@src/app/components/Core/directives/generals/validatorError";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { LiquidacionService } from "../services/liquidacion.service";

export class LiquidacionSettings {


  constructor() { }

  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      {


        nombre: new TegettFormControl({
          formState: "",
          validatorOrOpts: [Validators.required,],
          label: "Nombre",
          typeField: TypeField.Input,
          required: true,
          nameControl: "nombre",

        }),
        fechaInicio: new TegettFormControl({
          formState: new Date(),
          validatorOrOpts: [Validators.required],
          label: "Fecha inicio del período",
          typeField: TypeField.Date,
          required: true,
          nameControl: "fechaInicio",
        }),
        fechaFin: new TegettFormControl({
          formState: new Date(),
          validatorOrOpts: [Validators.required],
          label: "Fecha fin del período",
          typeField: TypeField.Date,
          required: true,
          nameControl: "fechaFin",
        }),
        descripcion: new TegettFormControl({
          formState: "",
          validatorOrOpts: [],
          label: "Descripción",
          typeField: TypeField.Input,
          nameControl: "descripcion",

        }),




      },{validators:rangoFechasValidator('fechaInicio', 'fechaFin')}
      ,
      []
    );
    return formRecord;
  }



}
